<template>

    <div class="top_content">
        <div class="leftcontent">
            <div class="logo">
                <img src="../../../static/zyywhjxy.png" alt="logo" />
            </div>
            <div v-show="isshow" class="search">
                <a-input-search placeholder="请输入您想要的视频" style="width:10.35rem;height: 1.26rem;" @search="search" v-model="keywords"/>
            </div>
        </div>


‘
        <div class="wrpCls">
            <a-button type="link" @click="logout()" block style="color: #121212;font-size: 0.68rem;">
                退出登录
            </a-button>

        </div>
    </div>

</template>

<script>
    import { getItem,removeItem } from "@/utils/storage"

    
    export default {
        name: "header",
        data(){
            return{
                keywords:""
                
            }
        },
        props:['isshow'],
        mounted(){

        },
        methods:{
            logout(){


                removeItem("Token");

                this.$router.push({ path: "/newlogin" });
            },
            search(){
                this.$emit("search",this.keywords);
            },
            ukeyload(){
                
            }
        }
    }
</script>

<style scoped>

    .logo {

        display: flex;
        cursor: pointer;
        width: 8.57rem;
        height: 1.09rem;
        justify-items: center;
    img{
        height: 100%;
        width: 100%;
        vertical-align: middle;
        display: inline-block;
    }
    }

    .top_content{
        width: 100%;
        display: flex;
        height: 1.51rem;


        flex-direction: row;
        justify-content: space-between;
    }
    .leftcontent{
        display: flex;
        flex-direction: row;
        justify-items: center;

    }

    .search{
        margin-left: 3.51rem;

        display: flex;


    }
    .wrpCls{
        height: 1.51rem;

        display: flex;
        justify-items: center;
    }
    ::v-deep .ant-input{
        border-radius: 0.7rem;
        font-size: 0.66rem;
        color: #939393;
        height: 1.41rem;
        width: 10.35rem;
        background: #F2F2F2;
    }
    ::v-deep .ant-input-affix-wrapper{
        font-size: 0.66rem;
    }
    ::v-deep .ant-input-suffix{
        top:55%;
    }
</style>